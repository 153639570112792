<template>
    <div class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
        <div class="ui-layout-content--full ui-layout-flex--1" style="height: 510px">
            <ex-search-table-pagination
                ref="searchTable"
                :fetch="$client.getAlarmEventDetail"
                :columns="columns"
                :formOptions="formOptions"
                :searchWhenSortChange="true"
                @selection-change="selectHandle"
                :paramsMethod="searchParams"
            >
            </ex-search-table-pagination>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ViewDetail',
    props: {
        id: {
            type: Number,
            default: null
        },
        type: {
            type: String,
            default: 'detail'
        },
    },
    data() {
        return {
            selectedRows: [],
            deviceTypeList: [
                {name: '环网柜', value: 'RING_MAIN_UNIT'},
                {name: '变电站', value: 'SUBSTATION'},
            ],
            dataCategoryList: [
                {name: '遥信', value: 1},
                {name: '遥测', value: 2},
                {name: '公共数据', value: 3},
            ],
            dataTypeList: [
                {name: '浮点型', value: 1},
                {name: '布尔型', value: 2},
                {name: '整型', value: 3},
                {name: '字符串', value: 4},
            ],
        };
    },
    computed: {
        columns() {
            return [
                {
                    prop: 'deviceType',
                    label: '设备类型',
                    width: 80,
                    formatter: (row, column, cellValue) => {
                        for (let obj of this.deviceTypeList) {
                            if (obj.value === cellValue) {
                                return obj.name;
                            }
                        }
                        return cellValue;
                    }
                },
                {
                    prop: 'deviceName',
                    label: '设备名称',
                },
                {
                    prop: 'dataCategory',
                    label: '类别',
                    width: 60,
                    formatter: (row, column, cellValue) => {
                        for (let obj of this.dataCategoryList) {
                            if (obj.value === cellValue) {
                                return obj.name;
                            }
                        }
                        return cellValue;
                    }
                },
                {
                    prop: 'cabinetGroup',
                    label: '组柜',
                    width: 60,
                },
                {
                    prop: 'tagName',
                    label: '标签名称',
                },
                {
                    prop: 'dataType',
                    label: '数据类型',
                    width: 70,
                    formatter: (row, column, cellValue) => {
                        for (let obj of this.dataTypeList) {
                            if (obj.value === cellValue) {
                                return obj.name;
                            }
                        }
                        return cellValue;
                    }
                },
                {
                    prop: 'dataValue',
                    label: '值',
                    width: 80,
                },
                {
                    prop: 'collectionTimeValue',
                    label: '采集时间',
                    width: 150,
                }
            ];
        },
        formOptions() {
            return {
                forms: [
                    {
                        prop: 'tagName',
                        itemType: 'input',
                        clearable: true,
                        placeholder: '请输入标签名称',
                        label: '标签名称',
                    },
                ],
            };
        },
    },
    methods: {
        searchParams(params) {
            params.id = this.id;
            return params;
        },
        selectHandle(val) {
            this.selectedRows = val;
        },
        closeModalHandle() {
            this.dialog.visible = false;
        },
    },
    created() {
    }
};
</script>
<style lang="less" scoped>
</style>
